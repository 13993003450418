

export const APP_INFO = {
    NAME: 'DevOps Platform Services Communications',
    DISPLAY_NAME: 'DevOps Platform Services Communications',
    URL: 'https://subscribe.developer.gov.bc.ca',
};

export const SSO_IDP = {
    GITHUB: 'github',
    IDIR: 'idir',
};